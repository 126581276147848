<template>
  <b-card>
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="text-danger"
        />
        <span class="align-middle ml-50">Documents</span>
      </h4>
    </template>

    <b-card>
      <b-card-text>
        <ul
          v-for="(file, index) in applicationFiles"
          :key="index"
          class="list-style"
        >
          <li
            class="mb-1"
          >
            <b-link
              :href="file.url"
              target="_blank"
            >
              {{ formattedFileName(file) }}
            </b-link>
            <b-link>
              <b-badge
                variant="light-primary"
                @click="downloadDocument(file)"
              >
                download
              </b-badge></b-link>
          </li>
        </ul>
      </b-card-text>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BBadge,
} from 'bootstrap-vue'

import { downloadExportFile } from '@core/mixins/downloadExportFile'

import useUserDocuments from '@/views/components/user-documents/useUserDocuments'
import { fetchFile } from '@/api/files'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
  },
  mixins: [downloadExportFile],
  props: {
    userData: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      applicationFiles: [],
    }
  },
  watch: {
    userData() {
      this.setApplicationFiles()
    },
  },
  async created() {
    await this.setApplicationFiles()
  },
  setup() {
    const {
      fetchApplicationFiles,
      fetchFile,
    } = useUserDocuments()

    return {
      fetchApplicationFiles,
      fetchFile,
    }
  },
  methods: {
    formattedFileName(file) {
      const parts = []

      if (file.program_name) {
        parts.push(`${file.program_name} /`)
      }
      if (file.user_name) {
        parts.push(`${file.user_name} /`)
      }
      parts.push(file.name)

      return parts.join(' ')
    },
    async setApplicationFiles() {
      await this.fetchApplicationFiles(this.applicationId).then(response => {
        this.applicationFiles = response
      })
    },
    async downloadDocument(file) {
      this.isLoading = true
      await fetchFile(file.path).then(response => {
        this.isLoading = false
        if (response) {
          const filename = this.formattedFileName(file)
          const fileExt = file.path.split('.').pop()
          this.downloadFile(response, filename.replace(/\s/g, '-'), fileExt)
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style>

</style>
